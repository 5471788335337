<template>
  <div>
<!--    <div v-if="datalist.title!=null">
      &lt;!&ndash;<van-row>
        <van-col span="22" class="mt3 mright mr10">
          <span class="txt">{{datainfo.mdfuser.nickName}}</span>
        </van-col>
        <van-col span="2" class="mt4 mr10">
          <van-image width="2rem" height="2rem" round :src="userinfo.headimgurl"></van-image>
        </van-col>
      </van-row>&ndash;&gt;
      <van-row>
        <van-col span="16" offset="1" class="mt4">{{datalist.title}}</van-col>
      </van-row>
      <div class="line"></div>
      <div v-for="(item,key) in datalist.groups" :key="key" class="mt20">
        <van-row>
          <van-col span="23" offset="1" class="mb10">{{item.title}}
            <span v-if="item.type == 'list'" class="ml15" @click="changeshow"><van-icon name="arrow-down" color="#e5e5e5" size="14"/></span>
          </van-col>
        </van-row>
          <van-row v-if="item.type == 'data'">
            <van-col :span="22" offset="1" class="mb11 ml6v">
              <div v-for="(items,keys) in item.fields" :key="keys">
              <van-row>
                <van-col :span="24"><span style="color: #7f7f7f;">{{items.title}}</span></van-col>
              </van-row>
              <van-row>
                <van-col :span="24" >
                  <van-cell-group>
                    <div v-if="items.type=='select'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''" :value="datainfo[items.name].label" />
                      <van-cell v-else  />
                    </div>
                    <div v-else-if="items.type=='mutiselect'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''" >
                        <template #default>
                          <span v-for="(i,ke) in datainfo[items.name]" :key="ke">{{i.label}}&nbsp;</span>
                        </template>
                      </van-cell>
                      <van-cell v-else />
                    </div>
                    <div v-else-if="items.type=='contacts'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum">
                      </van-cell>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type == 'img'||items.type == 'file'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"  >
                        <template #extra>
                          <van-image width="3rem" height="4rem" v-for="(it,k) in datainfo[items.name].list" :key="k" :src="it" class="mr5" @click="showimg(datainfo[items.name].list)">
                            <template v-slot:loading>
                              <van-loading type="spinner" size="16" />
                            </template>
                          </van-image>
                        </template>
                      </van-cell>
                      <van-cell v-else >
                        &lt;!&ndash;                     <template #extra>
                                               <van-image width="3rem" height="3rem" v-for="(it,k) in img" :key="k" :src="it" @click="showimg(img)">
                                                 <template v-slot:loading>
                                                   <van-loading type="spinner" size="16" />
                                                 </template>
                                               </van-image>
                                             </template>&ndash;&gt;
                      </van-cell>
                    </div>
                    <div v-else>
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''" :value="datainfo[items.name]"></van-cell>
                      <van-cell v-else  />
                    </div>
                  </van-cell-group>
                </van-col>
              </van-row>
              </div>
            </van-col>
          </van-row>
          <van-row v-if="item.type == 'list'&&show == true" class="mb11 ">
            <van-col span="22" offset="1" class="ml6v" v-for="(it,ke) in datainfo[item.name]" :key="ke">
              <div v-for="(items,keys) in item.fields" :key="keys">
              <span style="color: #7f7f7f;" class="mb7">{{ items.title }}：</span>
              <span v-if="items.type == 'mutiselect'">
                <span v-for="(i,k) in it[itms.name]" :key="k">{{ i.label }}</span>
              </span>
              <span v-else-if="items.type == 'select'">
                {{ it[items.name].label }}
&lt;!&ndash;                {{ it[items.name].label.nickName!=undefined?it[items.name].label.nickName:it[items.name].label}}&ndash;&gt;
              </span>
                <span v-else-if="items.type == 'contacts'">{{ it[items.name].nickName +' '+ it[items.name].phoneNum }}</span>
                <span v-else-if="items.type == 'img'">
                <van-image width="3rem" height="4rem" v-for="(itt,k) in it[items.name].list" :key="k" :src="itt" class="mr5" @click="showimg(it[items.name].list)">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="16" />
                  </template>
                </van-image>
              </span>
              <span v-else>{{ it[items.name] }}</span>
              </div>
              <div class="line1"></div>
            </van-col>
          </van-row>
        </div>
        <div class="line"></div>
    </div>-->
    <div v-if="dis == 3" class="mcenter mt45vh">
      <div class="border p10">
        <open-app :ids="ids"></open-app>
      </div>
    </div>
    <div v-else>
      <info-display :datalist="datalist" :datainfo="datainfo" :ids="ids"></info-display>
    </div>

  </div>
</template>

<script>
import qs from "qs";
import {get} from "@/utils/request";
import {Toast} from "vant";
import mixins from '@/mixins'
import infoDisplay from '@/components/infoDisplay'
import openApp from "@/components/openApp";

export default {
  name: "Message",
  mixins: [mixins],
  data(){
    return{
      imgUrl:"http://bullpm-1253367923.cos.ap-chengdu.myqcloud.com",
      imgCompress:"?imageMogr2/thumbnail/!70p",
      companyId:"",
      img: [require('@/assets/sixu.png'),require('@/assets/10.jpg')],
      code: "",
      state: "",
      datalist:{},//获取的数据格式
      userinfo:{},
      datainfo:{},
      datadef: {},
      ids: {
        dataId: "",
        appId: "",
        companyId: "",
        upDataId: "",
        FiledStatus: "",
      },
      dis: "",
      visible: false,
    }
  },
  components: {
    infoDisplay,
    openApp,
  },
  created() {
    let flag = this.isWeixin();
    if (flag){
      this.getCode();
    }else {
      this.$router.push('notwx');
    }
    /*this.getCode();*/
  },
  methods:{
    getCode(){//获取code
      /*let href = "https://wx.sixu.work/message?code=041eLiGa1sXIgD0jGCJa1WvY9b2eLiGO&state=18688"
      if (href.indexOf("state") != -1){
        this.code= qs.parse(href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(href.split("#")[0].split("?")[1]).state;
        console.log("code",this.code);
        console.log("state",this.state);
      }*/
      if (window.location.href.indexOf("state") != -1){
        this.code = qs.parse(window.location.href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(window.location.href.split("#")[0].split("?")[1]).state
      }
      if (this.code){
        //调用接口，获取用户信息,和数据列表信息
        get('/sso/auth/msg/'+this.code+'/'+this.state).then(res=>{
          if (res!=null&&res.data!=null&&res.data.content!=null){
            //console.log('contents',res);
            this.dis = "";
            let contents = res.data.content;
            this.companyId = contents.form.companyId
            this.datalist = JSON.parse(contents.form.formDef);
            this.datainfo = {};
            if (!this.isEmptyData(contents.data)){
              this.datainfo = contents.data;
              if (!this.isEmptyData(contents.data.dataId)){
                this.ids = {
                  dataId: contents.data.dataId,
                  appId: contents.data.appId,
                  FiledStatus: contents.data.FiledStatus,
                  upDataId: contents.data.upDataId,
                }
              }
            }
            if (!this.isEmptyData(contents.form.dataDef)){
              let def = JSON.parse(contents.form.dataDef)
              if (!this.isEmptyData(def.viewData.visible)){
                this.visible = true;
                this.datadef.visible = def.viewData.visible;
              }else {
                this.visible = false;
              }
              //console.log("datadef",this.datadef);
            }
            if (!this.isEmptyData(contents.form.companyId)){
              this.ids.companyId = contents.form.companyId;
            }
            //console.log('form',this.datalist);
            /*alert(JSON.stringify(this.datalist));
            alert(JSON.stringify(this.datainfo));*/
            for (let i in this.datalist.groups){
              let item = this.datalist.groups[i];
              for (let j in item.fields){
                let items = item.fields[j];
                items.visible = true;
                if (items.type == 'img'){//当有img时，会组装img路径如下所示
                  if (this.datainfo != null && this.datainfo != ''){
                    if (this.datainfo[items.name]!=null&&this.datainfo[items.name]!=''){
                      this.datainfo[items.name].list = [];
                      for (let k in this.datainfo[items.name]){
                        let d = this.datainfo[items.name][k];
                        if (d.fileName!=null && d.fileName!=''){
                          this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName + this.imgCompress);
                        }
                      }
                    }
                  }
                }
                if (items.type == 'file'){
                  if (this.datainfo != null && this.datainfo != ""){
                    if (this.datainfo[items.name]!=null&&this.datainfo[items.name]!=''){
                      this.datainfo[items.name].list = [];
                      for (let k in this.datainfo[items.name]){
                        let d = this.datainfo[items.name][k];
                        if (d.fileName!=null&&d.fileName!=''){
                          this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName);
                        }
                      }
                    }
                  }
                }
                if (items.type == 'sign') {
                  this.datainfo[items.name] = "data:image/png;base64," + this.datainfo[items.name]
                }
                //是否可见
                if (this.visible == true){
                  //console.log("hhh")
                  if (this.datadef.visible != null) {
                    if (!this.isEmptyData(this.datadef.visible[items.name]) && this.datadef.visible[items.name].indexOf('visible') === -1) {
                      items.visible = false;
                    }
                    if (this.isEmptyData(this.datadef.visible[items.name])){
                      items.visible = false;
                    }
                  }
                }
              }
            }

          }else {
            if (res.data.errorCode == "500"){
              Toast.fail(res.data.errorMsg);
              this.dis = 3;
                /*if (res.data.errorMsg == "数据不存在！"){}else {
              }*/
            }else {
              Toast.fail(res.data.errorMsg);
            }
          }
        }).catch(err=>err)
      }else {//
      }
    },
  }
}
</script>

<style scoped>
</style>